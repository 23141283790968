import React, { createContext, useContext, useState, useEffect } from "react";
import { verifyMe } from "../services/auth.service";
import { getToken, setToken } from "../Utils/LocalStorage";
import { makeLogout } from "../Utils/auth";

const UserContext = createContext(null);

export const useUser = () => {
  const data = useContext(UserContext);

  if (!data) throw new Error("useUser must be wrapped inside provider!");

  return data;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = getToken();

        if (token) {
          const response = await verifyMe();
          setUser(response.user);
          setSubscription(response.subscription);
        }
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const makeLogin = (userData, activeSubscription, token) => {
    setSubscription(activeSubscription);
    setUser(userData);
    setToken(token);
  };

  const logout = () => {
    makeLogout();
  };

  return (
    <UserContext.Provider
      value={{ user, loading, setUser, makeLogin, logout, subscription }}
    >
      {!loading && children}
    </UserContext.Provider>
  );
};
