import {
  MdDashboard, // Dashboard
  MdCheckCircle, // Verifications
  MdCreditCard, // Subscription
  MdSupport, // Support
  MdQrCodeScanner, // QrCode Scanner (used as a barcode scanner)
  MdPeople, // Employees
  MdHistory, // Audit Logs
} from "react-icons/md"; // Material Design Icons

import { appPaths } from "../../constants/urls";
import { FaUser } from "react-icons/fa";

export const staffMenuItems = [
  {
    link: `/${appPaths.staff.home}/${appPaths.staff.dashboard}`,
    label: "Dashboard",
    icon: <MdDashboard />,
  },
  {
    link: `/${appPaths.staff.home}/${appPaths.staff.verifications}`,
    label: "Verifications",
    icon: <MdCheckCircle />,
  },
  {
    link: `/${appPaths.staff.home}/${appPaths.staff.subscription}`,
    label: "Subscription",
    icon: <MdCreditCard />,
  },
  {
    link: `/${appPaths.staff.home}/${appPaths.staff.support}`,
    label: "Support",
    icon: <MdSupport />,
  },
  {
    link: `/${appPaths.staff.home}/${appPaths.staff.settings}`,
    label: "Profile Settings",
    icon: <FaUser />,
  },
];

export const agencyMenuItems = [
  {
    link: `/${appPaths.agency.home}/${appPaths.agency.dashboard}`,
    label: "Dashboard",
    icon: <MdDashboard />,
  },
  {
    link: `/${appPaths.agency.home}/${appPaths.agency.verifiedEmployes}`,
    label: "Employees",
    icon: <MdPeople />,
  },
  {
    link: `/${appPaths.agency.home}/${appPaths.agency.subscription}`,
    label: "Subscription",
    icon: <MdCreditCard />,
  },
  // {
  //   link: `/${appPaths.agency.home}/${appPaths.agency.barcodeScanner}`,
  //   label: "Barcode Scanner",
  //   icon: <MdQrCodeScanner />, 
  // },
  {
    link: `/${appPaths.agency.home}/${appPaths.agency.support}`,
    label: "Support",
    icon: <MdSupport />,
  },
  {
    link: `/${appPaths.agency.home}/${appPaths.agency.settings}`,
    label: "Profile Settings",
    icon: <FaUser />,
  },
];

export const adminMenuItems = [
  {
    link: `/${appPaths.admin.home}/${appPaths.admin.dashboard}`,
    label: "Dashboard",
    icon: <MdDashboard />,
  },
  {
    link: `/${appPaths.admin.home}/${appPaths.admin.companies}`,
    label: "Companies",
    icon: <MdPeople />,
  },
  {
    link: `/${appPaths.admin.home}/${appPaths.admin.verifications}`,
    label: "Verifications",
    icon: <MdCheckCircle />,
  },
  // {
  //   link: `/${appPaths.admin.home}/${appPaths.admin.barcodeScanner}`,
  //   label: "Barcode Scanner",
  //   icon: <MdQrCodeScanner />, 
  // },
  {
    link: `/${appPaths.admin.home}/${appPaths.admin.subscriptionsOverview}`,
    label: "Subscriptions Overview",
    icon: <MdCreditCard />,
  },
  {
    link: `/${appPaths.admin.home}/${appPaths.admin.support}`,
    label: "Support",
    icon: <MdSupport />,
  },
  {
    link: `/${appPaths.admin.home}/${appPaths.admin.audit}`,
    label: "Audit Logs",
    icon: <MdHistory />,
  },
  {
    link: `/${appPaths.admin.home}/${appPaths.admin.settings}`,
    label: "Profile Settings",
    icon: <FaUser />,
  },
];

export const facilityMenuItems = [
  {
    link: `/${appPaths.facility.home}/${appPaths.facility.dashboard}`,
    label: "Dashboard",
    icon: <MdDashboard />,
  },
  {
    link: `/${appPaths.facility.home}/${appPaths.facility.barcodeScanner}`,
    label: "Barcode Scanner",
    icon: <MdQrCodeScanner />, // Using QrCodeScanner for barcode functionality
  },
  {
    link: `/${appPaths.facility.home}/${appPaths.facility.verifiedEmployes}`,
    label: "Employees",
    icon: <MdPeople />,
  },
  {
    link: `/${appPaths.facility.home}/${appPaths.facility.assignedStaff}`,
    label: "Assigned Staff",
    icon: <MdPeople />,
  },
  {
    link: `/${appPaths.facility.home}/${appPaths.facility.subscriptions}`,
    label: "Subscriptions",
    icon: <MdCreditCard />,
  },
  {
    link: `/${appPaths.facility.home}/${appPaths.facility.support}`,
    label: "Support",
    icon: <MdSupport />,
  },
  {
    link: `/${appPaths.facility.home}/${appPaths.facility.settings}`,
    label: "Profile Settings",
    icon: <FaUser />,
  },
];
