import React, { useState } from "react";
import { Card, Col, Row } from "antd";
import PageHeading from "../../components/shared/PageHeading";
import Ticket from "./Ticket";
import TicketView from "./TicketView";
import { useSearchParams } from "react-router-dom";

const Support = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Read specific query parameter
  const topicId = searchParams.get("topicId");

  const handleChatClick = (chat) => {
    setSearchParams({ topicId: chat.id });
  };

  return (
    <>
      <PageHeading heading={"VeriHealth Support"} />
      <Row gutter={16} className="mt-3">
        <Col xs={24} md={8}>
          <Card
            className="px-3 py-2"
            bordered={false}
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              height: "75vh",
              marginBottom: "10px",
            }}
          >
            <Ticket onChatClick={handleChatClick} selectedChatId={topicId} />{" "}
            {/* Pass selectedChat?.id */}
          </Card>
        </Col>
        <Col xs={24} md={16}>
          <Card
            className={`px-3 py-2 ${topicId ? "selected-chat" : ""}`}
            bordered={false}
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              height: "75vh",
            }}
          >
            {topicId ? (
              <TicketView chat={topicId} />
            ) : (
              <div className="w-100 h-100 nochat">
                <p style={{ textAlign: "center", marginTop: "40%" }}>
                  No chat selected
                </p>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Support;
