import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input, Row, Col, Spin, Select } from "antd"; // Import Spin for loading indicator
import { MdSend } from "react-icons/md";
import {
  createThreadMessages,
  loadThreadMessages,
  updateThread,
} from "../../services/thread.service";
import { useUser } from "../../providers/user-provider";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { threadStatus, threadStatusMap } from "../../constants/thread";
import { userRoles } from "../../constants";

const { Option } = Select;

const TicketView = ({ chat }) => {
  const [imageUrl, setImageUrl] = useState(
    JSON.parse(localStorage.getItem("user"))?.imageUrl || ""
  );
  const { user } = useUser();
  const [newMessage, setNewMessage] = useState("");
  const [thread, setThread] = useState(null);
  const [messages, setMessages] = useState([]);

  const [loading, setLoading] = useState(false); // Loading state
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const messagesEndRef = useRef(null); // Create a ref for the messages container

  useEffect(() => {
    loadMessages();
  }, [chat]);

  const updateThreadStatus = (data) => {
    updateThread(chat, {status: data})
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadMessages = useCallback(() => {
    setLoading(true); // Start loading
    loadThreadMessages(chat, { page, limit: 100 })
      .then((res) => {
        console.log(res);
        setTotal(res.count);
        setThread(res.thread);
        setMessages(res.data?.reverse());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false); // End loading
      });
  }, [chat, total, page]);

  useEffect(() => {
    const handleUserUpdate = () => {
      const updatedUser = JSON.parse(localStorage.getItem("user"));
      if (updatedUser) {
        setImageUrl(updatedUser.imageUrl);
      }
    };

    window.addEventListener("user-update", handleUserUpdate);
    return () => {
      window.removeEventListener("user-update", handleUserUpdate);
    };
  }, []);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      createThreadMessages({ content: newMessage.trim(), threadId: chat })
        .then((res) => {
          setMessages((prev) => [...prev, res]);
          setNewMessage("");
          scrollToBottom(); // Scroll to the bottom after sending the message
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom when messages change
  }, [messages]);

  return (
    <>
      <Row style={{ borderBottom: "1px solid #b6b7b9", paddingBottom: "10px" }}>
        <Col span={24}>
          <h3 style={{ color: "#000000", fontWeight: "700", margin: 0 }}>
            {thread?.user?.firstName} {thread?.user?.lastName}
          </h3>
          {user.role === userRoles.ADMIN && (
            <Select
              placeholder="Status"
              // value={status}
              onChange={(value) => updateThreadStatus(value)}
              style={{ width: "100%" }}
            >
              {Object.values(threadStatus).map((key) => (
                <Option value={key} key={key}>
                  {threadStatusMap[key]}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>

      <div
        style={{
          height: "calc(75vh - 150px)",
          overflowY: "auto",
          margin: "10px 0",
        }}
      >
        {loading ? ( // Show loading spinner when loading
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <Spin
              indicator={
                <Loading3QuartersOutlined
                  spin
                  style={{ fontSize: "40px", color: "#000000" }}
                />
              }
            />
          </div>
        ) : (
          messages?.map((message) => (
            <div
              key={message.id}
              style={{
                display: "flex",
                justifyContent:
                  message.userId === user?.id ? "flex-end" : "flex-start",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  background:
                    message.userId === user?.id ? "#000000" : "#f0f0f0",
                  color: message.userId === user?.id ? "#fff" : "#000",
                  borderRadius:
                    message.userId === user?.id
                      ? "20px 20px 0 20px"
                      : "20px 20px 20px 0",
                  maxWidth: "60%", // Adjust max width as needed
                  padding: "10px",
                  display: "inline-block",
                }}
              >
                {message.content}
              </div>
            </div>
          ))
        )}
        <div ref={messagesEndRef} /> {/* This div acts as the scroll target */}
      </div>

      <div style={{ textAlign: "center", margin: "10px 0" }}>
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onPressEnter={handleSendMessage}
          placeholder="Type message here..."
          style={{
            borderRadius: "10px",
            borderColor: "#000000",
            width: "100%",
          }} // Full width
          suffix={
            <MdSend
              onClick={handleSendMessage}
              style={{ color: "#000000", cursor: "pointer" }}
            />
          }
        />
      </div>
    </>
  );
};

export default TicketView;
