import { makeRequest } from "./makeRequest";

export function createThread(data) {
  return makeRequest(`/v1/tickets`, "POST", data);
}

export function listThread(params) {
  return makeRequest(`/v1/tickets`, "GET", null, { params });
}


export function updateThread(id, data) {
  return makeRequest(`/v1/tickets/${id}`, "PATCH", data);
}

export function createThreadMessages(data) {
  return makeRequest(`/v1/tickets/message`, "POST", data);
}

export function loadThreadMessages(id, params) {
  return makeRequest(`/v1/tickets/message/${id}`, "GET", null, { params });
}
