import React from "react";
import { Layout, Menu, Drawer, Button } from "antd";
import { NavLink } from "react-router-dom";
import {
  staffMenuItems,
  agencyMenuItems,
  adminMenuItems,
  facilityMenuItems,
} from "./shared/MenuItems";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import logo from "../assets/Logo.png";
import { useUser } from "../providers/user-provider";
import { userRoles } from "../constants";

const { Sider } = Layout;

const LeftBar = ({ isMobile, drawerVisible, toggleDrawer }) => {
  const { user } = useUser();

  let menuItems;
  switch (user?.role) {
    case userRoles.ADMIN:
      menuItems = adminMenuItems;
      break;
    case userRoles.AGENCY:
      menuItems = agencyMenuItems;
      break;
    case userRoles.FACILITY:
      menuItems = facilityMenuItems;
      break;
    case userRoles.STAFF:
      menuItems = staffMenuItems;
      break;
    default:
      menuItems = [];
  }

  return (
    <>
      {/* Sidebar for larger screens */}
      {!isMobile && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          style={{
            background: "#000000",
            height: "100%",
            position: "fixed",
            left: 0,
          }}
        >
          <div className="logo" style={{ height: "64px", background: "#000000", padding: "10px" }}>
            <div style={{ textAlign: "center", height: "100%", width: "100%" }}>
              <img src={logo} alt="Veri Health Logo" style={{ height: "100%" }} />
            </div>
          </div>
          <Menu mode="inline" style={{ paddingTop: "20px", background: "transparent" }}>
            {menuItems.map((item) => (
              <NavLink key={item.link} to={item.link} className={({ isActive }) => (isActive ? "active-link" : "")}>
                {({ isActive }) => (
                  <Menu.Item
                    key={item.link}
                    icon={item.icon}
                    style={{
                      color: isActive ? "#000000" : "white",
                      background: isActive ? "#04ECF0" : "transparent",
                      paddingLeft: "10px",
                    }}
                  >
                    {item.label}
                  </Menu.Item>
                )}
              </NavLink>
            ))}
          </Menu>
        </Sider>
      )}

      {/* Drawer for mobile screens */}
      {isMobile && (
        <>
          {/* <Button
            className="menu-toggle"
            icon={<MenuOutlined />}
            style={{
              position: "fixed",
              top: 10,
              left: 10,
              zIndex: 1000,
              backgroundColor: "#000000",
              color: "#ffffff",
            }}
            onClick={toggleDrawer}
          /> */}

          <Drawer
            style={{ width: "250px" }}
            title={
              <div style={{ textAlign: "end" }}>
                {/* <img src={logo} alt="Veri Health Logo" style={{ height: "40px" }} /> */}
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={toggleDrawer}
                  style={{ color: "white" }}
                />
              </div>
            }

            placement="left"
            onClose={toggleDrawer}
            visible={drawerVisible}
            bodyStyle={{ padding: 0, background: "#000000" }}
            headerStyle={{ background: "#000000", borderBottom: "none" }}
          >
            <Menu mode="inline" style={{ paddingTop: "20px", background: "transparent" }}>
              {menuItems.map((item) => (
                <NavLink key={item.link} to={item.link} className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleDrawer}>
                  {({ isActive }) => (
                    <Menu.Item
                      key={item.link}
                      icon={item.icon}
                      style={{
                        color: isActive ? "#000000" : "white",
                        background: isActive ? "#04ECF0" : "transparent",
                        paddingLeft: "10px",
                      }}
                    >
                      {item.label}
                    </Menu.Item>
                  )}
                </NavLink>
              ))}
            </Menu>
          </Drawer>
        </>
      )}
    </>
  );
};

export default LeftBar;
