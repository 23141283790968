export const AUTH_TOKEN_KEY = "vh_auth";

export const userRoles = {
  ADMIN: "admin",
  STAFF: "staff",
  FACILITY: "facility",
  AGENCY: "agency",
};

export const userVerificationStatus = {
  INACTIVE: 0,
  UPLOADED: 1,
  SUBMITTED: 2,
  IN_PROGRESS: 3,
  APPROVED: 4,
  REJECTED: 5,
  EXPIRED: 6,
};

export const userVerificationStatusMap = {
  [userVerificationStatus.INACTIVE]: "Document missing",
  [userVerificationStatus.UPLOADED]: "Documents Uploaded",
  [userVerificationStatus.SUBMITTED]: "Documents Submitted",
  [userVerificationStatus.IN_PROGRESS]: "In Review",
  [userVerificationStatus.APPROVED]: "Approved",
  [userVerificationStatus.REJECTED]: "Rejected",
  [userVerificationStatus.EXPIRED]: "Document Expired",
};

export const staffAssignmentStatus = {
  ASSIGNED: 0,
  ACTIVE: 1,
  PAYMENT_EXPIRED: 2,
};

export const staffPositions = {
  PERSONAL_SUPPORT_WORKER: 1,
  REGISTERED_PRACTICAL_NURSE: 2,
  REGISTERED_NURSE: 3,
};

export const staffPositionMap = {
  [staffPositions.PERSONAL_SUPPORT_WORKER]: "PERSONAL SUPPORT WORKER",
  [staffPositions.REGISTERED_PRACTICAL_NURSE]: "REGISTERED PRACTICAL NURSE",
  [staffPositions.REGISTERED_NURSE]: "REGISTERED NURSE",
};
// export const staffPositionMap = {
//   [staffPositions.PERSONAL_SUPPORT_WORKER]: "Personal Support Worker",
//   [staffPositions.REGISTERED_PRACTICAL_NURSE]: "Registered practical nurse",
//   [staffPositions.REGISTERED_NURSE]: "Registered Nurse",
// };

export const countries = [
  {
    title: "Canada",
    key: "canada",
  },
  {
    title: "USA",
    key: "usa",
  },
];

export const countriesMap = Object.fromEntries(countries.map(({key, title})=>([key, title])))

export const states = {
  canada: [
    { title: "Alberta", key: "alberta" },
    { title: "British Columbia", key: "british_columbia" },
    { title: "Manitoba", key: "manitoba" },
    { title: "New Brunswick", key: "new_brunswick" },
    { title: "Newfoundland and Labrador", key: "newfoundland_and_labrador" },
    { title: "Nova Scotia", key: "nova_scotia" },
    { title: "Ontario", key: "ontario" },
    { title: "Prince Edward Island", key: "prince_edward_island" },
    { title: "Quebec", key: "quebec" },
    { title: "Saskatchewan", key: "saskatchewan" },
    { title: "Yukon", key: "yukon" },
    { title: "Nunavut", key: "nunavut" },
    { title: "Northwest Territories", key: "northwest_territories" },
  ],
  usa: [
    { title: "Alabama", key: "alabama" },
    { title: "Alaska", key: "alaska" },
    { title: "Arizona", key: "arizona" },
    { title: "Arkansas", key: "arkansas" },
    { title: "California", key: "california" },
    { title: "Colorado", key: "colorado" },
    { title: "Connecticut", key: "connecticut" },
    { title: "Delaware", key: "delaware" },
    { title: "District Of Columbia", key: "district_of_columbia" },
    { title: "Florida", key: "florida" },
    { title: "Georgia", key: "georgia" },
    { title: "Hawaii", key: "hawaii" },
    { title: "Idaho", key: "idaho" },
    { title: "Illinois", key: "illinois" },
    { title: "Indiana", key: "indiana" },
    { title: "Iowa", key: "iowa" },
    { title: "Kansas", key: "kansas" },
    { title: "Kentucky", key: "kentucky" },
    { title: "Louisiana", key: "louisiana" },
    { title: "Maine", key: "maine" },
    { title: "Maryland", key: "maryland" },
    { title: "Massachusetts", key: "massachusetts" },
    { title: "Michigan", key: "michigan" },
    { title: "Minnesota", key: "minnesota" },
    { title: "Mississippi", key: "mississippi" },
    { title: "Missouri", key: "missouri" },
    { title: "Montana", key: "montana" },
    { title: "Nebraska", key: "nebraska" },
    { title: "Nevada", key: "nevada" },
    { title: "New Hampshire", key: "new_hampshire" },
    { title: "New Jersey", key: "new_jersey" },
    { title: "New Mexico", key: "new_mexico" },
    { title: "New York", key: "new_york" },
    { title: "North Carolina", key: "north_carolina" },
    { title: "North Dakota", key: "north_dakota" },
    { title: "Ohio", key: "ohio" },
    { title: "Oklahoma", key: "oklahoma" },
    { title: "Oregon", key: "oregon" },
    { title: "Pennsylvania", key: "pennsylvania" },
    { title: "Rhode Island", key: "rhode_island" },
    { title: "South Carolina", key: "south_carolina" },
    { title: "South Dakota", key: "south_dakota" },
    { title: "Tennessee", key: "tennessee" },
    { title: "Texas", key: "texas" },
    { title: "Utah", key: "utah" },
    { title: "Vermont", key: "vermont" },
    { title: "Virginia", key: "virginia" },
    { title: "Washington", key: "washington" },
    { title: "West Virginia", key: "west_virginia" },
    { title: "Wisconsin", key: "wisconsin" },
    { title: "Wyoming", key: "wyoming" },
  ],
};

export const statesMap = Object.fromEntries(Object.values(states).flat().map(({key, title})=>([key, title])))