import { Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'

const PageHeading = ({heading}) => {
    return (
        <>
            <Row justify="space-between" style={{ marginBottom: "10px" }}>
                <Col>
                    <Title level={3}>{heading}</Title>
                  
                </Col>
              
            </Row>
        </>
    )
}

export default PageHeading