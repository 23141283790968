import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCompaniesById, removeStaff } from '../../services/company.service';
import Loader from '../../components/shared/Loading';
import PageHeading from '../../components/shared/PageHeading';
import { Card, Col, message, Popconfirm, Row, Tag, Tooltip, Table } from 'antd';
import dayjs from 'dayjs';
import { DeleteFilled, LeftOutlined } from '@ant-design/icons';

const CompanyDetails = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PAGE_LIMIT = 10;

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      setLoading(true);
      try {
        const data = await getCompaniesById(companyId, {
          includeStaff: true,
          page,
          limit: PAGE_LIMIT,
        });
        setCompany(data?.user);
        setTotal(data?.totalStaff || 0);
      } catch (error) {
        console.error("Error fetching company details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [companyId, page]);

  const handleDelete = async (staffId) => {
    try {
      await removeStaff(staffId);
      message.success("User deleted successfully.");
      setCompany((prev) => ({
        ...prev,
        staff: prev.staff.filter((s) => s.id !== staffId),
      }));
    } catch (err) {
      message.error(err?.message || "Something went wrong");
    }
  };

  if (loading) {
    return <Loader />;
  }

  const positionMapping = {
    1: "PERSONAL_SUPPORT_WORKER",
    2: "REGISTERED_PRACTICAL_NURSE",
    3: "REGISTERED_NURSE",
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, staff) => `${staff.firstName} ${staff.lastName}`,
    },
    {
      title: 'Reference Code',
      dataIndex: 'companyCode',
      key: 'companyCode',
      render: (companyCode) => companyCode || 'N/A',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      render: (position) => positionMapping[position] || 'N/A',
    },
    {
      title: 'Assigned to Facility',
      dataIndex: 'facility',
      key: 'facility',
      render: () => 'N/A',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, staff) => (
        <Tooltip title="Delete">
          <Popconfirm
            title="Are you sure you want to delete this staff member?"
            onConfirm={() => handleDelete(staff.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled style={{ color: "#ff4d4f", cursor: "pointer" }} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];

  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return ''; 
    const cleaned = ('' + phoneNumber).replace(/\D/g, ''); 
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      
      return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return phoneNumber; // Return original if format doesn't match
  }
  

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ display: 'flex', alignItems: 'start', cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <LeftOutlined style={{ fontSize: '18px', marginRight: '8px', marginTop: "8px" }} />
        <PageHeading heading="Company Details" />
      </div>

      <Card title="Company Information" style={{ marginBottom: "20px" }}>
        <Row gutter={16}>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Company Id:</h3>
            <Tag>{company?.id || 'N/A'}</Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Company Email:</h3>
            <Tag>{company?.email || 'N/A'}</Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Reference Code:</h3>
            <Tag>{company?.companyCode || 'N/A'}</Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Status:</h3>
            <Tag color={company?.isActive ? "green" : ""}>
              {company?.isActive ? "Active" : "Inactive"}
            </Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Country:</h3>
            <Tag>{company?.country || 'N/A'}</Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Address:</h3>
            <Tag>{company?.companyAddress || 'N/A'}</Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Representative Name:</h3>
            <Tag>{`${company?.firstName || ''} ${company?.lastName || ''}`}</Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Subscription Status:</h3>
            <Tag>
              {company?.regisrationCompleted ? "Subscribed" : "Not Subscribed"}
            </Tag>
          </Col>
          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Phone:</h3>
            <Tag>{formatPhoneNumber(company?.companyPhone)}</Tag>
          </Col>

          <Col xs={24} md={12} style={{ marginBottom: "20px" }}>
            <h3>Updated On:</h3>
            <Tag>{dayjs(company?.updatedAt).format("YYYY-MM-DD")}</Tag>
          </Col>
        </Row>
      </Card>

      <Card title="Company Staff">
        <Table
          columns={columns}
          dataSource={company?.staff || []}
          rowKey={(staff) => staff.id}
          pagination={{
            pageSize: PAGE_LIMIT,
            current: page,
            total,
            onChange: setPage,
          }}
        />
      </Card>
    </div>
  );
};

export default CompanyDetails;
