export const threadStatus = {
  OPENDED: 1,
  ASSIGNED: 2,
  INPROGRESS: 3,
  CLOSED: 4,
};

export const threadStatusMap = {
  [threadStatus.OPENDED]: { label: "New", color: "blue" },
  [threadStatus.ASSIGNED]: { label: "Assigned", color: "orange" },
  [threadStatus.INPROGRESS]: { label: "In-Progress", color: "green" },
  [threadStatus.CLOSED]: { label: "Closed", color: "gray" },
};


export const threadType = {
  BUG: 1,
  SUGGESTION: 2,
  DEACTIVATE: 3,
  OTHER: 4,
};