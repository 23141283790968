import { makeRequest } from "./makeRequest";

export function verifyMe() {
  return makeRequest("/v1/auth/me");
}

export function login(credentials) {
  return makeRequest("/v1/auth/login", "POST", credentials);
}


export function resetPassword(credentials) {
  return makeRequest("/v1/auth/reset-password", "PATCH", credentials);
}


export function forgotUser(credentials) {
  return makeRequest("/v1/auth/forget", "POST", credentials);
}

export function signup(data) {
  return makeRequest("/v1/auth/signup", "POST", data);
}

export function verifyEmail(data) {
  return makeRequest("/v1/auth/verify-email", "POST", data);
}

export function resendOtp(data) {
  return makeRequest("/v1/auth/resend-otp", "POST", data);
}

