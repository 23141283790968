import { Layout } from "antd";
import React, { Suspense, useState, useEffect } from "react";
import LeftBar from "../LeftBar";
import HeaderComponent from "../Header";
import { Outlet } from "react-router-dom";
import Loader from "../shared/Loading";

const { Header, Content } = Layout;

const RootLayout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1100);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <LeftBar isMobile={isMobile} drawerVisible={drawerVisible} toggleDrawer={toggleDrawer} />
      <Layout>
        <Header style={{ background: "#000000", padding: 0 }}>
          <HeaderComponent onLogoClick={toggleDrawer} />
        </Header>
        <Content
          style={{
            padding: "10px",
            overflowY: "auto",
            height: "calc(100vh - 64px)",
            marginLeft: isMobile ? 0 : 200,
          }}
        >
          <Suspense fallback={<Loader/>}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default RootLayout;
