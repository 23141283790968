import { makeRequest } from "./makeRequest";

export function getCompanies(params) {
  return makeRequest(`/v1/users`, "GET", null, { params });
}
export function getCompaniesById(id,params) {
  return makeRequest(`/v1/users/${id}`, "GET", null, { params });
}
export function updateCompanyStatus(id, status) {
  return makeRequest(`/v1/users/${id}`, "PATCH", { isActive: status });
}

export function getCompany(id, params) {
  return makeRequest(`/v1/users/${id}`, "GET", null, { params });
}

export function getDashboardStats() {
  return makeRequest(`/v1/users/company-dashboard-stats`);
}

export function getStaff(params) {
  return getCompanies(params);
}

export function generateCode() {
  return makeRequest(`/v1/users/generate-code`, "POST", {});
}

export function getCompanySuggestions(search) {
  return makeRequest(
    `/v1/users/facility-suggestions`,
    "GET",
    {},
    { params: { search } }
  );
}

export function listAssignedStaff(params) {
  return makeRequest(`/v1/users/list-assigned-staff`, "GET", {}, { params });
}

export function removeStaff(id) {
  return makeRequest(`/v1/users/staff/${id}`, "DELETE");
}

export function removeCompany(id) {
  return makeRequest(`/v1/users/company/${id}`, "DELETE");
}
