import axios from "axios";
import { getToken, removeToken } from "../Utils/LocalStorage";

// Create axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Attach token to every request
api.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle 401 errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

const handleLogout = () => {
  removeToken();
  window.location.reload();
};

// Make request function with axios
export async function makeRequest(
  endpoint,
  method = "GET",
  data = null,
  opt = {}
) {
  const options = {
    url: endpoint,
    method,
    data,
    ...opt,
  };

  try {
    const response = await api(options);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error?.response?.data;
  }
}
