import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { useUser } from "./providers/user-provider";
import Loader from "./components/shared/Loading";

function App() {
  const { loading } = useUser();

  if (loading) {
    return <Loader/>
  }
  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
