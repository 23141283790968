import { lazy } from "react";

const Login = lazy(() => import("../pages/Login"));
const AdminLogin = lazy(() => import("../pages/Admin/AdminLogin"));
const Signup = lazy(() => import("../pages/Signup/Signup"));
const Forgot = lazy(() => import("../pages/Forgot"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));

export const publiRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/admin-login",
    element: <AdminLogin />,
  },
  {
    path: "/forgot",
    element: <Forgot />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
];
