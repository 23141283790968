import React from "react";
import { useUser } from "../providers/user-provider";
import { Navigate } from "react-router-dom";
import { makeLogout } from "../Utils/auth";

function RequireAuth({ roles = [], children }) {
  const { user } = useUser();

  if (!user){
    makeLogout();
    return <Navigate to={"/login"} />;
  }

  if (!user.isVerified || !user.regisrationCompleted)
    return <Navigate to={"/signup"} />;

  if (roles.includes(user.role)) {
    return children;
  } else {
    return null;
  }
}

export default RequireAuth;
