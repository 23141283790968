import React from "react";
import { useUser } from "../providers/user-provider";
import { userRoles } from "../constants";
import { Navigate } from "react-router-dom";

function Navigation() {
  const { user } = useUser();

  const getPath = () => {
    switch (user?.role) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.AGENCY:
        return "/agency/dashboard";
      case userRoles.FACILITY:
        return "/facility/dashboard";
      case userRoles.STAFF:
        return "/staff/dashboard";
      default:
        return "/login";
    }
  };

  const redirectPath = getPath();

  return <Navigate to={redirectPath} />;
}

export default Navigation;
