import React, { useCallback, useEffect, useState } from "react";
import { Table, Typography, Space, Card, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import PageHeading from "../../components/shared/PageHeading";
import { listLogs } from "../../services/audit-logs.service";
import { userRoles } from "../../constants";

const { Title } = Typography;

const LIMIT = 5;

const AuditLogs = () => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    loadAuditLogs();
  }, [page]);

  const loadAuditLogs = useCallback(() => {
    listLogs({ page, limit: LIMIT })
      .then((res) => {
        console.log(res);
        setTotal(res.count);
        setLogs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => dayjs(text).format("DD MMMM YYYY ( HH:mm)"),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user) => {
        if (user.role === userRoles.STAFF) {
          return `${user?.firstName} ${user?.lastName}`;
        } else {
          return user?.companyName;
        }
      },
    },
    {
      title: "Role",
      dataIndex: "user",
      key: "user",
      render: (user) => user?.role,
    },
    {
      title: "Company Name",
      dataIndex: "user",
      key: "user",
      render: (user) => user?.companyName,
    },
  
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <PageHeading heading={"Audit Logs"} />

      <Card>
        <Table
          dataSource={logs}
          columns={columns}
          pagination={{ total: total, pageSize: LIMIT, onChange: setPage }}
          rowKey="key"
        />
      </Card>
    </div>
  );
};

export default AuditLogs;
