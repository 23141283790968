import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "antd/dist/reset.css";
import "./index.css";
import { UserProvider } from "./providers/user-provider";

const AppWrapper = () => (
  <UserProvider>
    <App />
  </UserProvider>
);

const root = createRoot(document.getElementById("root"));
root.render(<AppWrapper />);
