import React from "react";
import {
  Layout,
  Avatar,
  Space,
  Typography,
  Tag,
  Button,
  Grid, // Import Grid for responsive design
} from "antd";
import { UserOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { makeLogout } from "../Utils/auth";
import { useUser } from "../providers/user-provider";
import logo from "../assets/Logo.png";
import { staffPositionMap } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { appPaths } from "../constants/urls";

const { Header } = Layout;
const { Text } = Typography;
const { useBreakpoint } = Grid; // Use useBreakpoint for responsive design

const HeaderComponent = ({ onLogoClick }) => {
  const { user } = useUser();
  const screens = useBreakpoint(); // Get the current screen size

  const handleLogout = () => {
    makeLogout();
    window.location.reload()
  };

  return (
    <Header
      style={{
        background: "#000000",
        padding: "0 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "3",
        }}
      >
        <Button
          className="menu-toggle"
          icon={<MenuOutlined style={{ fontSize: "20px" }} />}
          onClick={onLogoClick}
          style={{
            backgroundColor: "#000",
            color: "#04ECF0",
            marginTop: "10px",
            border: "none",
          }}
        />
        <img
          src={logo}
          alt="Veri Health Logo"
          style={{ height: "40px", marginLeft: "10px" }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <>
          {screens.md && (
            <Tag
              bordered
              color="#04ECF0"
              style={{ color: "black", fontSize: "14px", borderRadius: "30px" }}
            >
              {user?.role === "staff"
                ? staffPositionMap[user.position]
                : user?.role === "agency"
                ? "Agency"
                : user?.role}
            </Tag>
          )}

          {/* <Link to={`/${user?.role}/settings`}>
            <Avatar
              size="large"
              style={{ background: "#04ECF0", color: "black" }}
              icon={<UserOutlined />}
            />
          </Link> */}
        </>

        <Space onClick={handleLogout} style={{ cursor: "pointer" }}>
          <LogoutOutlined
            style={{
              height: "3em",
              width: "2em",
              justifyContent: "center",
              fontSize: "30px",
              color: "#04ECF0",
            }}
          />
        </Space>
      </div>
    </Header>
  );
};

export default HeaderComponent;
