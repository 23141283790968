import { lazy } from "react";
import RootLayout from "../components/layouts/RootLayout";
import RequireAuth from "../components/RequireAuth";
import { userRoles } from "../constants";
import Navigation from "../pages/Navigation";
import { appPaths } from "../constants/urls";
import Support from "../pages/Support/Support";
import AuditLogs from "../pages/Admin/AuditLogs";
import CompanyDetails from "../pages/Admin/CompanyDetails";

const AdminDashboard = lazy(() => import("../pages/Admin/AdminDashboard"));
const Dashboard = lazy(() => import("../pages/Staff/Dashboard"));
const CompanyDashboard = lazy(() =>
  import("../pages/Companies/CompanyDashboard")
);
const Verification = lazy(() => import("../pages/Staff/Verification"));
const Settings = lazy(() => import("../pages/Staff/Settings"));
const Subscription = lazy(() => import("../pages/common/Subscription"));
const VerifiedEmployees = lazy(() =>
  import("../pages/Companies/VerifiedEmployees")
);
const AssignedStaff = lazy(() => import("../pages/Companies/AssignedStaff"));
const BarcodeScanner = lazy(() => import("../pages/Companies/ValidateStaff"));
const AdminCompanies = lazy(() => import("../pages/Admin/AdminCompanies"));
const AdminSubscription = lazy(() =>
  import("../pages/Admin/AdminSubscription")
);

const AdminVerification = lazy(() =>
  import("../pages/Admin/AdminVerification")
);

export const privateRoutes = [
  {
    path: appPaths.staff.home,
    element: <RootLayout />,
    children: [
      {
        path: appPaths.staff.dashboard,
        element: (
          <RequireAuth roles={[userRoles.STAFF]}>
            <Dashboard />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.staff.verifications,
        element: (
          <RequireAuth roles={[userRoles.STAFF]}>
            <Verification />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.staff.subscription,
        element: (
          <RequireAuth roles={[userRoles.STAFF]}>
            <Subscription />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.staff.settings,
        element: (
          <RequireAuth roles={[userRoles.STAFF]}>
            <Settings />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.staff.support,
        element: (
          <RequireAuth roles={[userRoles.STAFF]}>
            <Support />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: appPaths.agency.home,
    element: <RootLayout />,
    children: [
      {
        path: appPaths.agency.dashboard,
        element: (
          <RequireAuth roles={[userRoles.AGENCY]}>
            <CompanyDashboard />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.agency.verifiedEmployes,
        element: (
          <RequireAuth roles={[userRoles.AGENCY]}>
            <VerifiedEmployees />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.agency.subscription,
        element: (
          <RequireAuth roles={[userRoles.AGENCY]}>
            <Subscription />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.agency.barcodeScanner,
        element: (
          <RequireAuth roles={[userRoles.AGENCY]}>
            <BarcodeScanner />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.agency.settings,
        element: (
          <RequireAuth roles={[userRoles.AGENCY]}>
            <Settings />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.agency.support,
        element: (
          <RequireAuth roles={[userRoles.AGENCY]}>
            <Support />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: appPaths.facility.home,
    element: <RootLayout />,
    children: [
      {
        path: appPaths.facility.dashboard,
        element: (
          <RequireAuth roles={[userRoles.FACILITY]}>
            <CompanyDashboard />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.facility.verifiedEmployes,
        element: (
          <RequireAuth roles={[userRoles.FACILITY]}>
            <VerifiedEmployees />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.facility.barcodeScanner,
        element: (
          <RequireAuth roles={[userRoles.FACILITY]}>
            <BarcodeScanner />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.facility.settings,
        element: (
          <RequireAuth roles={[userRoles.FACILITY]}>
            <Settings />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.facility.subscriptions,
        element: (
          <RequireAuth roles={[userRoles.FACILITY]}>
            <Subscription />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.facility.assignedStaff,
        element: (
          <RequireAuth roles={[userRoles.FACILITY]}>
            <AssignedStaff />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.facility.support,
        element: (
          <RequireAuth roles={[userRoles.FACILITY]}>
            <Support />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: appPaths.admin.home,
    element: <RootLayout />,
    children: [
      {
        path: appPaths.admin.dashboard,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <AdminDashboard />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.companies,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <AdminCompanies />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.companyDetails,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <CompanyDetails />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.verifications,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <AdminVerification />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.subscriptionsOverview,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <AdminSubscription />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.profile,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <AdminSubscription />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.barcodeScanner,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <BarcodeScanner />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.settings,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <Settings />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.support,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <Support />
          </RequireAuth>
        ),
      },
      {
        path: appPaths.admin.audit,
        element: (
          <RequireAuth roles={[userRoles.ADMIN]}>
            <AuditLogs />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <RequireAuth roles={Object.values(userRoles)}>
        <Navigation />
      </RequireAuth>
    ),
  },
];
